<template>
  <plain-button
    type="router-link"
    :to="{ name: 'account', query: { section: 'shipping-returns'} }"
    class="return-pickups-cta">
    <square-image
      class="return-pickups-img me-3"
      :width="isMobile ? 48 : 64"
      :image-source="returnPickupsImg"/>
    <div>
      <p class="mb-0 small-text">
        No need to leave your house. Schedule <span class="semi-bold">free</span> return pickups from your home!
      </p>
      <h6 class="text-secondary small-text semi-bold mb-0">
        Learn More
      </h6>
    </div>
  </plain-button>
</template>

<script>
import PlainButton from '../../global/sequin/PlainButton.vue'
import SquareImage from '../../global/sequin/SquareImage.vue'
export default {
  components: {
    PlainButton,
    SquareImage
  },
  computed: {
    returnPickupsImg () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}returnpickups.png`
    }
  }
}
</script>

<style lang="scss" scoped>
.return-pickups-cta {
  width: 100%;
  border-radius: 4px;
  background: $origami;
  padding: 12px 16px;
  display: flex;
  align-items: center;
}

.return-pickups-img {
  flex-shrink: 0;
}
</style>
