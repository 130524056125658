<template>
  <div
    id="app"
    ref="app"
    :class="[{'is-scroll-locked': isAnyFlyoutOpen }, ...experimentClasses]">
    <div :style="contentContainerStyle">
      <div
        class="app-container"
        :class="{'prospect': !isAuthenticated }">
        <NavBar v-if="!hideNavBar"/>
        <div :class="appFrameClasses">
          <BannersToasts/>
          <RouterView/>
          <MobileProspectFooter v-if="!isAuthenticated && !hideNavBar && !isDesktop && showVariant"/>
          <BaseFooter v-if="$route.name === 'closet-sections'"/>
        </div>
      </div>
    </div>
    <ModalsIndex/>
    <FlyoutsIndex/>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useStore } from 'vuex'
import { useOverlaysStore } from '@/stores/overlays.js'
import BannersToasts from './components/headers/BannersToasts'
import ModalsIndex from './components/modals/ModalsIndex'
import FlyoutsIndex from './components/flyouts/FlyoutsIndex'
import NavBar from '@shared/components/navigation/NavBar.vue'
import useClient from '@/composables/client'
import useScreenSize from '@shared/composables/screenSize'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'
import MobileProspectFooter from '@shared/components/ADORN/MobileProspectFooter.vue'
import BaseFooter from '@/components/global/BaseFooter.vue'
import useScrollLock from '@shared/composables/scrollLock.js'
import useRouteMetadata from '@/composables/routeMetadata.js'
import { useExperimentsStore } from '@shared/stores/experiments.js'

if (process.env.NODE_ENV === 'development') {
  window.armoire = {
    user: {
      username: process.env.VUE_APP_USERNAME,
      email: process.env.VUE_APP_EMAIL,
      first_name: process.env.VUE_APP_FIRST_NAME,
      last_name: process.env.VUE_APP_LAST_NAME
    }
  }
}

const app = ref(null)
const appContainerTop = ref(null)

const store = useStore()
const router = useRouter()

const overlaysStore = useOverlaysStore()
const { closeLatest } = overlaysStore
const { isAnyFlyoutOpen, savedScrollPositions } = storeToRefs(overlaysStore)
const { isDesktop } = useScreenSize()

const initialRoute = computed(() => store.state.global.initialRoute)
const { isAuthenticated } = useClient()
const contentContainerStyle = computed(() => isAnyFlyoutOpen.value ? { position: 'relative', top: appContainerTop.value } : {})
const appFrameClasses = computed(() => {
  return {
    'app-frame g-0': isAuthenticated.value && !hideNavBar.value,
    'app-frame-prospect g-0': !isAuthenticated.value && !hideNavBar.value,
    'container-fluid': !fullScreen.value,
    'app-frame-no-nav g-0': hideNavBar.value
  }
})
const { fullScreen, hideNavBar } = useRouteMetadata()
const { showVariant } = useHomepageRedesignExperiment()
const { experimentClasses } = storeToRefs(useExperimentsStore())

router.beforeEach(() => {
  if (appContainerTop.value) {
    // We're navigating away while a flyout is open, so
    // we'll save the scroll position in case we come back.
    savedScrollPositions.value.push(-appContainerTop.value)
  }
})

watch(isAnyFlyoutOpen, (newValue) => {
  if (newValue) {
    appContainerTop.value = app.value.getBoundingClientRect().y
  } else {
    nextTick(() => {
      const top = -appContainerTop.value
      appContainerTop.value = null
      window.scrollBy({
        left: 0,
        top,
        behavior: 'instant'
      })
    })
  }
})

useScrollLock()

onMounted(() => {
  store.commit('global/SET_WINDOW_WIDTH', window.innerWidth)
  store.commit('global/SET_WINDOW_HEIGHT', window.innerHeight)
  window.addEventListener('resize', handleResize)
  window.addEventListener('message', handleMessage)
  window.addEventListener('keydown', handleKeyInput)
  app.value.addEventListener('click', hashTagClick)
})
onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  window.removeEventListener('message', handleMessage)
  window.removeEventListener('keydown', handleKeyInput)
})

function handleKeyInput (e) {
  if (e.key === 'Escape') {
    if (closeLatest()) {
      e.stopPropagation()
    }
  }
}

function handleResize () {
  store.commit('global/SET_WINDOW_WIDTH', window.innerWidth)
  store.commit('global/SET_WINDOW_HEIGHT', window.innerHeight)
}

async function handleMessage (message) {
  if (message.data === 'armoireAppInfoLoaded') {
    store.commit('client/UPDATE_CLIENT', { username: window.armoireAppUserInfo.username, email: window.armoireAppUserInfo.email, loaded: false })
    store.dispatch('global/setAuthTokenHeader', window.armoireAppUserInfo.authToken)

    if (isAuthenticated.value) {
      await store.dispatch('client/getClient')
    }
    if (initialRoute.value) {
      router.replace({ name: initialRoute.value })
    }
  }
}

function hashTagClick (e) {
  // Check to make sure this is from our v-html because
  // we don't want to handle clicks from other things in
  // the Vue
  if (!e.target.classList.contains('hashtaglink')) {
    return
  }

  // get the target tag
  // the 2nd class will always contain the target tag
  const tagClass = e.target.classList[1]
  const tag = tagClass.split('-')[1]

  e.stopPropagation()
  e.preventDefault()
  router.push({ name: 'feed-item-tag', params: { tag: tag } })
}
</script>

<style lang="scss">
@import "~@/assets/styles/global";

.header-nav {
  position: sticky;
  top: 0;
  z-index: $zindex-sticky + 2;
}
.content {
  min-height: 70%;
}
.is-scroll-locked {
  height: 100vh;
  overflow: hidden;
  left: 0;
  right: 0;
}
.app-frame {
  padding-top: $nav-bar-height-mobile;
  @include media-desktop  {
    padding-left: $desktop-nav-width;
    padding-top: 0;
  }
}
.app-frame-prospect {
  padding-top: $nav-bar-height-mobile;
  @include media-desktop  {
    padding-top: $nav-bar-height-desktop;
  }
}
</style>
