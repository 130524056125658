<template>
  <div class="d-flex align-items-center">
    <sequin-button
      v-if="!item.reviewed"
      small
      class="me-2"
      :variant="reviewButtonVariant"
      @click="reviewItem(item)">
      Review
    </sequin-button>
    <div
      v-else
      class="d-flex align-items-center me-2 text-pewter">
      <svg-check
        height="13"
        class="ms-n1"/>
      <p class="small-text text-pewter me-1 mb-0">
        Reviewed
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { vuexAccessors } from '../../global/helpers/vuex'
import MyCaseMixin from '../common/MyCaseMixin.vue'
import SequinButton from '../../global/sequin/SequinButton'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    SequinButton,
    SvgCheck
  },
  mixins: [MyCaseMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...vuexAccessors('review', [
      'reviewItems'
    ]),
    reviewButtonVariant () {
      return this.isReviewOOC || (!this.canSwapItems && !this.canSelectItems) ? 'primary' : 'secondary'
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    reviewItem (item) {
      this.reviewItems = [item]
      this.closeFlyout()
      this.$router.push({ name: 'review-item', params: { reviewItemId: item.id, singleItem: true } })
    }
  }
}
</script>
