<template>
  <div
    class="text-light border-bottom px-xs py-md"
    :class="{'bg-teal' : type === 'smart', 'bg-primary' : type === 'default'}">
    <div
      class="d-flex align-items-center justify-content-between">
      <span class="d-flex align-items-center">
        <div
          :style="{flexBasis: iconSize}">
          <svg-discover
            :width="iconSize"
            :height="iconSize"/>
        </div>

        <p
          class="mb-0 ms-3"
          :class="{'d-flex align-items-center' : !hasTitleSlot}">
          <b v-if="hasTitleSlot">
            <slot
              name="title"/>
            <br>
          </b>
          <span :class="{'ps-xxs' : !isTabletOrSmaller && !hasTitleSlot}">
            <slot name="text"/>
          </span>
        </p>
      </span>
      <TextLink
        v-if="link"
        class="update-link"
        :class="{'ms-lg' : isTabletOrSmaller}"
        variant="inherit"
        :type="link.type"
        :to="link.to"
        :underline="false">
        <b>{{ link.title }}
        </b>
      </TextLink>
    </div>
  </div>
</template>
<script setup>
import useScreenSize from '@shared/composables/screenSize.js'
import TextLink from '@/components/global/sequin/TextLink.vue'
import { computed, useSlots } from 'vue'
import SvgDiscover from '@/components/global/svg/SvgDiscover.vue'

const { isTabletOrSmaller } = useScreenSize()
defineProps({
  link: {
    type: Object,
    default: null
  },
  type: {
    type: String,
    default: 'default'
  }
})
const iconSize = computed(() => isTabletOrSmaller.value ? 25 : 35)
// Using slots() to get the current slots
const slots = useSlots()

// Checking if the title slot exists and is not empty
const hasTitleSlot = computed(() => !!slots.title && !!slots.title()[0].children)

</script>
