<template>
  <div
    v-if="!hideBanners"
    id="banners">
    <div
      v-if="isImpersonated && !stylistClosetInProgress"
      class="d-flex justify-content-end align-items-center card-panel text-center bg-warning p-3">
      <p>You are impersonating <b>{{ clientFullName }}</b></p>
      <a
        class="btn btn-danger px-5 mx-2"
        href="/impersonate/stop/?next=/admin/clients/client/">
        Stop
      </a>
    </div>
    <StylingBanner v-if="impersonatorIsStylist"/>
    <SaleBanner v-if="sale"/>
    <BaseBanner
      v-for="banner in banners"
      :key="banner"
      :link="banner.link"
      :type="banner.type">
      <template #title>
        {{ banner.title }}
      </template>
      <template #text>
        <div
          v-if="banner.name === 'FirstCaseTimeoutBanner'"
          class="align-self-center">
          <CountdownTimer
            use-days
            :cutoff="new Date(firstCaseDueDate)">
            <template #after>
              <b> left to fill your first case</b>
              before we automatically send you a stylist-filled case.
            </template>
          </CountdownTimer>
        </div>
        <span
          v-else>
          {{ banner.text }}
        </span>
      </template>
    </BaseBanner>
    <!-- Toast notifications should be last, below any banners etc. -->
    <ToastNotificationList/>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import ToastNotificationList from '@/components/global/ToastNotificationList.vue'
import StylingBanner from '@/components/headers/StylingBanner.vue'
import SaleBanner from '@/components/headers/SaleBanner.vue'
import BaseBanner from '@/components/headers/BaseBanner.vue'
import CountdownTimer from '@/components/global/dates/CountdownTimer.vue'
import useClient from '@/composables/client'
import useRouteMetadata from '@/composables/routeMetadata.js'
import { useStylingStore } from '@/stores/styling'

const { hideBanners } = useRouteMetadata()

const store = useStore()
const sale = computed(() => store.state.closet.sale)
const {
  isImpersonated,
  impersonatorIsStylist,
  firstCaseDueDate,
  name: clientFullName,
  paymentIsExpired,
  showFirstCaseCountdown
} = useClient()

const styling = useStylingStore()

const stylistClosetInProgress = computed(() => styling.stylistClosetInProgress)

const banners = computed(() => {
  const banners = []
  if (paymentIsExpired.value) {
    banners.push(
      {
        name: 'PaymentExpiredBanner',
        title: 'Your credit card has expired.',
        text: 'Please update your payment method.',
        link: { title: 'Update now', type: 'router-link', to: { name: 'account-billing' } },
        type: 'default'
      }
    )
  }
  if (showFirstCaseCountdown.value) {
    banners.push({
      name: 'FirstCaseTimeoutBanner',
      type: 'smart'
    })
  }
  return banners
})
</script>

<style lang="scss" scoped>
#banners {
  position: sticky;
  top: 0;
  z-index: $zindex-sticky
}
</style>
