<template>
  <div>
    <div
      v-if="hasHeaderSlot"
      class="core-section-header"
      :class="{ 'mobile': isMobile }">
      <slot name="header"/>
    </div>
    <div
      class="section"
      :class="{ 'no-padding': noPadding, 'mobile': isMobile }">
      <slot name="body"/>
    </div>
    <div
      v-if="hasFooterSlot"
      class="sticky-footer"
      :class="{ 'no-padding': noPadding, 'mobile': isMobile }">
      <slot name="sticky-footer"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noPadding: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    hasHeaderSlot () {
      return this.$slots.header
    },
    hasFooterSlot () {
      return this.$slots['sticky-footer']
    }
  }
}
</script>

<style lang="scss" scoped>
.core-section-header {
  background: $white;
  color: $pewter;
  text-align: center;
  position: relative;
  margin: 32px 48px 0;

  &.mobile {
    margin: 24px 32px 0;
  }
}

.section {
  padding: 24px 48px;

  &.mobile {
    padding: 20px;
  }

  &.no-padding {
    padding: 0;
  }
}

.sticky-footer {
  @extend .section;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: $default-border;
  background-color: $white;
}

</style>
